<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
      :title="isUpdate?$t('ads.update_btn'):$t('ads.new_btn')"
      :paths="[{title:$t('ads.title'),link:'ads'}]"
    />
    <section class='wameed-dashboard-page-content_body coupons_create'>
      <b-row class='px-2'>
        <b-col lg='12' md='12' class='px-0'>


          <wameed-form @onSubmit='submitOrder'>


            <div slot-scope='{ invalid }'>
              <b-card no-body class='wameed-card mb-5'>
                <b-card-header class='align-items-start tab-title'>
                  <h5 class='text-med-20 text-font-main mb-0'>
                    {{ isUpdate ? $t('ads.update_btn') : $t('ads.new_btn') }}
                  </h5>
                </b-card-header>
                <b-card-body
                  class='position-relative justify-content-between mt-5 px-5'
                >


                  <b-row>
                    <b-col md='12'>
                      <text-input
                        id='package-ad'
                        v-model='form.name'
                        :label="$t('ads.form.name')"
                        :placeholder="$t('form.text.placeholder')"
                        name='package-ad'
                        rules='required'
                      />
                    </b-col>

                    <b-col md='6'>

                      <!--          <wameed-input-file-upload-->
                      <!--            fileType='images'-->
                      <!--            field-classes='w-100'-->
                      <!--            rule='required'-->
                      <!--            functionType='front'-->
                      <!--            v-model='form.image.name'-->
                      <!--            :label="$t('ads.form.image')"-->
                      <!--            :input-placeholder="$t('form.image.placeholder')"-->
                      <!--            :input-append-text="$t('form.image.browse')"-->
                      <!--            @changed='uploadProfileImage'-->
                      <!--          />-->


                      <wameed-image-cropper-input
                        :label="$t('ads.form.image')"
                        :placeholder="$t('form.image.placeholder')"
                        :uploaded-image='profileImage'
                        :get-object='true'
                        :fixed='true'
                        @changed='uploadProfileImage'
                        :width='339'
                        :height='153'
                      />
                    </b-col>
                    <b-col md='6'>

                      <!--          <wameed-input-file-upload-->
                      <!--            fileType='images'-->
                      <!--            field-classes='w-100'-->
                      <!--            rule='required'-->
                      <!--            functionType='front'-->
                      <!--            v-model='form.image_web.name'-->
                      <!--            :label="$t('ads.form.image_web')"-->
                      <!--            :input-placeholder="$t('form.image.placeholder')"-->
                      <!--            :input-append-text="$t('form.image.browse')"-->
                      <!--            @changed='uploadWebImage'-->
                      <!--          />-->

                      <wameed-image-cropper-input
                        :label="$t('ads.form.image_web')"
                        :placeholder="$t('form.image.placeholder')"
                        :uploaded-image='webImage'
                        :get-object='true'
                        :fixed='true'
                        @changed='uploadWebImage'
                        :width='339'
                        :height='153'
                      />
                    </b-col>


                    <b-col md='6'>
                      <wameed-dropdown
                        v-model='form.redirect_type_obj'
                        :label="$t('ads.form.category')"
                        :placeholder="$t('form.text.placeholder')"
                        variant='disable'
                        :items='redirectTypes'
                        title='name'
                        class='wameed_dropdown'

                        :no_options="$t('common.no_options')"
                        @input='onTypeChange'
                      />
                    </b-col>
                    <b-col md='6' v-if='showVendorDropdown'>
                      <wameed-dropdown
                        :searchable='true'
                        v-model='form.vendor_type'
                        :label="$t('ads.form.vendor_type')"
                        :placeholder="$t('form.text.placeholder')"
                        variant='disable'
                        :items='getVendorTypes'
                        title='name'
                        class='wameed_dropdown'

                        :no_options="$t('common.no_options')"

                        @input='onVendorTypeChange'
                      />
                    </b-col>
                    <b-col md='6' v-if='showVendorDropdown && getVendors' :key='form.vendor_id'>
                      <wameed-dropdown
                        :searchable='true'
                        v-model='form.vendor'
                        :label="$t('ads.form.vendor')"
                        :placeholder="$t('form.text.placeholder')"
                        variant='disable'
                        :items='getVendors'
                        title='name'
                        class='wameed_dropdown'

                        :no_options="$t('common.no_options')"

                        @input='onVendorChange'
                      />
                    </b-col>
                    <b-col md='6' v-if='showRedirectTextInput'  :key='form.redirect'>
                      <text-input
                        id='redirect'
                        v-model='form.redirect'
                        :label="$t('ads.form.link')"
                        :placeholder="$t('form.text.placeholder')"
                        name='redirect'
                        rules='required'
                      />
                    </b-col>

                    <b-col md='6' v-if='showRedirectDropDown' :key='form.redirect'>
                      <wameed-dropdown
                        :searchable='true'
                        v-model='form.redirect_obj'
                        :label='form.redirect_type_obj.name'
                        :placeholder="$t('form.text.placeholder')"
                        variant='disable'
                        :items='getRedirects'
                        title='name'
                        class='wameed_dropdown'

                        :no_options="$t('common.no_options')"
                        @input='onRedirectChange'

                      />
                    </b-col>

                  </b-row>


                  <b-row>
                    <b-col md='12'>
                      <hr class='divider' />
                    </b-col>

                    <b-col md='6'>
                      <Wameed-date-picker
                        v-model='form.start_at'
                        placeholder='YYYY-MM-DD'
                        :config='{"type":"string","mask":"YYYY-MM-DD"}'
                        mode='date'
                        :label="$t('common.start_at')"
                        @input='clearExpireDate'
                      />
                    </b-col>
                    <b-col md='6'>
                      <Wameed-date-picker
                        v-model='form.end_at'
                        placeholder='YYYY-MM-DD'
                        :config='{"type":"string","mask":"YYYY-MM-DD"}'
                        mode='date'
                        :label="$t('common.end_at')"
                        :minDate='minEndDate'
                      />
                    </b-col>
                  </b-row>


                </b-card-body>

                <b-card-footer class='px-4 py-3'>
                  <div class='d-flex flex-wrap justify-content-between'>
                    <div class='px-4 py-3'>
                      <wameed-btn
                        classes='  text-med-14 text-white rounded-10'
                        :title="isUpdate?$t('btn.update'):$t('btn.add')"
                        :disabled='invalid'
                        type='submit'
                        variant='main'
                      />
                    </div>

                    <div class='px-4 py-3'>
                      <wameed-btn
                        classes='  text-med-14 text-font-secondary rounded-10 '
                        :title="$t('common.cancel')"
                        type='button'
                        variant='gray'
                        @onClick='goBack'
                      />
                    </div>
                  </div>
                </b-card-footer>
              </b-card>
            </div>
          </wameed-form>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { WameedBtn, WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';
import { mapActions, mapGetters } from 'vuex';
import { AdRedirectTypes, AdRedirectTypesTranslations } from '@/enums/userType.enum';
import ImageUploader from '@/views/pages/ads/image-uploader.vue';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import WameedImageCropperInput from '@/components/wameed/WameedImageCropperInput.vue';

export default {
  components: {
    WameedImageCropperInput,
    ImageUploader,
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    PageHeader,
    TextInput
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    modalData: {
      type: Object,
      default: null
    }

  },

  data() {
    return {
      form: {
        name: '',
        image: '',
        web_image: '',
        redirect_type_obj: '',
        redirect_type: 0,
        redirect_obj: '',
        redirect: '',
        vendor_type: '',
        vendor_type_id: '',
        vendor: '',
        vendor_id: '',
        start_at: '',
        end_at: ''
      },
      showSuccessModal: false
    };
  },
  computed: {
    ...mapGetters({
      getDetail: 'admin/ads/getDetail',
      getVendorTypes: 'admin/ads/getVendorTypes',
      getVendors: 'admin/ads/getVendors',
      getRedirects: 'admin/ads/getRedirects'
    }),

    isUpdate() {
      return !!(this.$route.name === 'ads-update' && this.$route.params.id);

    },
    profileImage() {

      return this.form.image;
    },

    webImage() {

      return this.form.web_image;
    },

    minEndDate() {
      let date = this.form.start_at;
      if (!date) {
        date = Date();
      }
      return new Date(date.toString()).toString();
    },
    showVendorDropdown() {
      return AdRedirectTypes.needVendors.includes(this.form.redirect_type);
    },
    showRedirectTextInput() {
      return AdRedirectTypes.textRedirect.includes(this.form.redirect_type);
    },
    showRedirectDropDown() {
      return AdRedirectTypes.dropdownRedirect.includes(this.form.redirect_type);
    },
    redirectTypes() {
      return AdRedirectTypesTranslations;
    }
  },
  async created() {
    await this.vendorTypes();
    if (this.isUpdate) {
      //get ad detail
      this._getDetail(this.$route.params.id).then(async () => {
        this.form = { ...this.getDetail };
        let redirect_type_obj = AdRedirectTypesTranslations.filter(type => type.id === this.form.redirect_type)[0];
        await this.onTypeChange(redirect_type_obj);

        if (this.getDetail.vendor_category_id != null) {
          let vendor_type = this.getVendorTypes.filter(type => type.id == this.getDetail.vendor_category_id)[0];

          await this.onVendorTypeChange(vendor_type);
          // await this.$store.dispatch('admin/ads/getVendors', this.getDetail.vendor_category_id);
          console.log('--------------------------');
          console.log('--------------------------');
          let vendor = this.getVendors.find(vendor => vendor.id == this.getDetail.vendor_id);
          await this.onVendorChange(vendor);
          if (this.showRedirectDropDown) {
            let redirect_obj = this.getRedirects.find(redirect => redirect.id == this.getDetail.redirect_to);
            this.onRedirectChange(redirect_obj);
            console.log(redirect_obj);
          }
          console.log('--------------------------');
          console.log('--------------------------');

        }
        if (this.showRedirectTextInput) {
          this.form = {
            ...this.form,
            redirect: this.getDetail.redirect_to
          };
        }

      });
    }
  },
  methods: {
    ...mapActions({
      vendorTypes: 'admin/ads/getVendorTypes',
      _getVendors: 'admin/ads/getVendors',
      _getDetail: 'admin/ads/getDetail',
      create: 'admin/ads/create',
      update: 'admin/ads/update'
    }),

    goBack() {
      this.$router.push({ name: 'ads' });
    },

    async onVendorTypeChange(vendorType) {
      this.form.vendor_type = vendorType;
      this.form.vendor_type_id = vendorType.id;


      this.form.vendor = null;
      this.form.vendor_id = null;
      await this.$store.dispatch('admin/ads/getVendors', this.form.vendor_type_id);

    },

    clearExpireDate() {
      if (this.form.start_at > this.form.end_at) {
        this.form.end_at = null;
      }
    },
    uploadProfileImage(value) {
      this.form.image = value;
    },
    uploadWebImage(value) {
      this.form={
        ...this.form,
        web_image : value
      }
    },

    async onTypeChange(type) {
      this.form.redirect_type_obj = type;
      this.form.redirect_type = type.id;

      await this.$store.dispatch('admin/ads/resetVendors');

      this.form.vendor = null;
      this.form.vendor_id = null;

      this.form.vendor_type = null;
      this.form.vendor_type_id = null;


      this.form.redirect = null;
      this.form.redirect_obj = null;
    },
    async onVendorChange(vendor) {
      this.form.vendor = vendor;
      this.form.vendor_id = vendor.id;
      if (this.form.redirect_type === AdRedirectTypes.product) {
        await this.$store.dispatch('admin/ads/getProducts', this.form.vendor_id);
      } else if (this.form.redirect_type === AdRedirectTypes.package) {
        await this.$store.dispatch('admin/ads/getPackages', this.form.vendor_id);
      }
      this.form.redirect = null;
      this.form.redirect_obj = null;
    },

    onRedirectChange(_redirect) {
      let redirect_obj = _redirect;
      let redirect = _redirect.id; // Initialize redirect here
      this.form = {
        ...this.form,
        redirect_obj,
        redirect // Use redirect here
      };
    },

    submitOrder() {

      if (!this.isUpdate) {
        this.create(this.form).then(() => {
          this.goBack();
          this.$store.commit('loadingFinish', null, { root: true });
        });
      } else {
        this.update({
          ...this.form,
          id: this.getDetail.id

        }).then(() => {
          this.goBack();
          this.$store.commit('loadingFinish', null, { root: true });
        });
      }
    }


  }
};
</script>
