import i18n from '@/libs/i18n';

const AdRedirectTypes = {

  none: 0,
  product: 1,
  package: 2,
  // discount: 3,
  vendor: 4,
  link: 5,

  needVendors: [1, 2, 4],
  textRedirect: [5],
  dropdownRedirect: [1, 2]
};


const AdRedirectTypesTranslations = [
  {
    id: 0,
    name: i18n.t('ads.options.none')
  },
  {
    id: 1,
    name: i18n.t('ads.options.product')
  },
  {
    id: 2,
    name: i18n.t('ads.options.package')
  },
  // {
  //   id: 3,
  //   name: i18n.t('ads.options.discount')
  // },
  {
    id: 4,
    name: i18n.t('ads.options.vendor')
  },
  {
    id: 5,
    name: i18n.t('ads.options.link')
  }


];

function filterAdRedirectTypes(id) {
  return AdRedirectTypesTranslations.find((item) => item.id === id);
}

export { AdRedirectTypes, AdRedirectTypesTranslations, filterAdRedirectTypes };
